<template>
  <div class="Media">
    <!-- <div class="num">01
    </div> -->
    <h3>media</h3>
    <h2>YOUR PARTNER</h2>
    <h6>CHOOSE YOUR PARTNER AND START THE ADVENTURE</h6>
    <!-- <h6>Pokémon can be seen in every corner of the world</h6> -->
    <img class="mline" src="@/assets/images/line.png" alt="">
    <div class="pic">
        <swiper ref="" class="" :options="swiperOptions">
            <swiper-slide><img preview="1" src="https://cdn.amusgame.net/game/6/2/2512856483665879045.jpg" /></swiper-slide>
            <swiper-slide><img preview="1" src="https://cdn.amusgame.net/game/6/2/2512856483665879046.jpg" /></swiper-slide>
            <swiper-slide><img preview="1" src="https://cdn.amusgame.net/game/6/2/2512856483665879047.jpg" /></swiper-slide>
        </swiper>
        <div>
            <div class="m-swiper-pagination" slot="pagination"></div> 
        </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'MediaSwiper',
  components: {
      Swiper,
      SwiperSlide
  },

  data() {
    return {
      swiperOptions: {
        autoplay: true,
        loop : true,
        pagination: {
            el: '.m-swiper-pagination',
            clickable: true,
        },
      },
    }
  },

  created() {
    
  },

  methods: {
  }

}
</script>

<style lang="less" scoped>
.Media {
  position: relative;
  margin: 20px 9px 20px 9px;
  color: #1C3986;
  text-align: center;
  background-color: white;
  .num {
    font-size: 48px;
    color: #4E97F9;
    font-weight: bold;
    position: absolute;
    width: 92px;
    height: 92px;
    top: 0px;
    left: 50%;
    transform: translate(-50%, 0);
    background-image: url("~@/assets/images/new/num_bg.png");
    background-size: 100% 100%;
    line-height: 90px;
  }
  h2, h3 {
    position: relative;
    z-index: 2;
  }
  h3 {
    font-weight: normal;
    font-size: 15px;
    opacity: 0;
  }
  h2 {
    font-weight: bold;
    font-size: 25px;
    color: black;
  }
  h6 {
    color: black;
    font-size: 12px;
  }

  .mline {
    width: calc( 100vw - 18px - 72px);
    height: 4.5vw;
    margin-top: 9px;
  }

  .pic {
      padding: 20px 36px 20px 36px;
      img {
          width: 77vw;
          height: 40vw;
      }
      .m-swiper-pagination {
        left: 0;
        margin-top: 15px;
        width: 100%;
        &/deep/ .swiper-pagination-bullet {
            margin: 0 12px;
            border: 3px solid black;
            background-color: none;
            width: 3px;
            height: 3px;
            &.swiper-pagination-bullet-active {
                background-color: black;
            }
        }
      }
  }
  
}
</style>