<template>
  <div class="drawAlert" v-show="show">
    <div class="bg"></div>
    <div class="content">
      <div class="title">
        <img src="~@/assets/images/new/pic_wartortle.png" alt="">
        {{ title }}
        <span class="close" @click="$emit('close')"></span>
      </div>
      <div class="contentText">
        <slot></slot>
        <img src="~@/assets/images/new/btn_ok.png" alt=""  @click="$emit('close')">
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: "DrawAlert",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''

    }
  },
  data() {
    return {

    }
  }
}
</script>
<style lang="less" scoped>
.drawAlert {
  .bg {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .67);
  }
  .content {
    position: fixed;
    z-index: 11;
    width: 300px;
    background-color: #F2F2F2;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    .title {
      height: 36px;
      background-image: url("~@/assets/images/new/bg_title.png");
      background-size: 100% 100%;
      font-size: 16px;
      position: relative;
      line-height: 36px;
      color: #000;
      font-weight: bold;
      img {
        width: 95px;
        position: absolute;
        top: -46px;
        left: -12px;
      }
      .close {
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        background: #FFFFFF;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
        border-radius: 50%;
        right: -10px;
        top: -10px;
        &::after {
          content: "";
          display: block;
          width: .01rem;
          height: 12px;
          background-color: #707070;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &::before {
          content: "";
          display: block;
          width: .01rem;
          height: 12px;
          background-color: #707070;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
    .contentText {
      padding: 20px 18px 8px;
      img {
        width: 106%;
        margin-left: -3%;
      }
    }
  }
}
</style>