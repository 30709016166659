<template>
  <div class="app_info">
    <img class="app-icon" src="@/assets/images/icon-en.png" alt="">
    <div class="store">
        <p class="txt">Classic 3D Pokémon RPG</p>
        <div>
          <img class="store_btn" src="@/assets/images/btn_apple_en.png" alt="" @click="$emit('registerClick')">
          <!-- <img class="store_btn" src="@/assets/images/btn_google_en.png" alt="" @click="$emit('registerClick')"> -->
        </div>
    </div>
    <!-- <img class="pic" src="@/assets/images/pet_pic_pokemon.png" alt=""> -->

  </div>
</template>

<script>
export default {
  name: 'AppInfo',
  components: {
  },

  data() {
    return {
      
    }
  },

  created() {
    
  },

  methods: {
  }

}
</script>

<style lang="less" scoped>

.app_info {
    width: 100%;
    height: 26.13vw;
    background: url(~@/assets/images/appinfo_bg.png) center center no-repeat;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;

    .app-icon {
        width: 60px;
        height: 60px;
        border-radius: 12px;
    }

    .store {
        display: flex;
        flex-direction: column;
        align-items: initial;
        .txt {
            height: 20px;
            color: black;
            font-size: 14px;
            font-family: 'din-bold';
        }
        .store_btn {
            width: 122px;
            height: 36px;
            margin-right: 10px;
        }
    }

    .pic {
        width: 110px;
        height: 52px;
    }
}

</style>