// import Qs from 'qs' 
import request from '@/axios'
export function requestGet({url, params = '', noLogin = null, noPrompt = null}) {
	let headers = null
	return request({
		url: '/api/web/' + url,
    method: 'get',
    params: params,
		headers: headers,
		noLogin: noLogin,
		noPrompt: noPrompt
	})
}
export function requestPost({ url, params = '', noLogin = null, noPrompt = null}) {
	let headers = null
	return request({
		url: '/api/web/' + url,
		method: 'post',
		data: params,
		headers: headers,
		noLogin: noLogin,
		noPrompt: noPrompt
	})
}
