<template>
  <div class="Media">
    <!-- <div class="num">01
    </div> -->
    <div class="petD">
      <img class="pet" src="@/assets/images/Charizard.png" alt="">
    </div>
    <h3>media</h3>
    <h2>MEDIA</h2>
    <h6>WHETHER IN THE SKY, SEA, FOREST, OR IN TOWNS,</h6>
    <h6>POKÉMON CAN BE SEEN IN EVERY CORNER OF THE WORLD</h6>
    <img class="mline" src="@/assets/images/line.png" alt="">
    <div class="pic">
        <img preview="0" src="https://cdn.amusgame.net/game/6/2/2512856481518395401.png" alt="">
        <img preview="0" src="https://cdn.amusgame.net/game/6/2/2512856481518395402.png" alt="">
        <img preview="0" src="https://cdn.amusgame.net/game/6/2/2512856481518395403.png" alt="">
        <img preview="0" src="https://cdn.amusgame.net/game/6/2/2512856481518395404.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Media',
  components: {
  },

  data() {
    return {
      
    }
  },

  created() {
    
  },

  methods: {
  }

}
</script>

<style lang="less" scoped>
.Media {
  position: relative;
  margin: 20px 9px 20px 9px;
  color: #1C3986;
  text-align: center;
  background-color: white;
  .petD {
    position: absolute;
    width: 29.3vw;
    height: 42.4vw;
    top: -32vw;
    right: 0px;
    overflow: hidden;
    .pet {
      width: 43.2vw;
      height: 42.4vw;
    }
  }
  .num {
    font-size: 48px;
    color: #4E97F9;
    font-weight: bold;
    position: absolute;
    width: 92px;
    height: 92px;
    top: 0px;
    left: 50%;
    transform: translate(-50%, 0);
    background-image: url("~@/assets/images/new/num_bg.png");
    background-size: 100% 100%;
    line-height: 90px;
  }
  h2, h3 {
    position: relative;
    z-index: 2;
  }
  h3 {
    font-weight: normal;
    font-size: 15px;
    opacity: 0;
  }
  h2 {
    font-weight: bold;
    font-size: 25px;
    color: black;
  }
  h6 {
    color: black;
    font-size: 12px;
  }

  .mline {
    width: calc( 100vw - 18px - 72px);
    height: 4.5vw;
    margin-top: 9px;
  }

  .pic {
      padding: 20px 36px 36px 36px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      img {
          margin-left: 2px;
          margin-bottom: 2px;
          width: calc( 50vw - 9px - 36px - 2px);
          height: 21.3vw;
      }
  }
  
}
</style>