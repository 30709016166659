












































import { Component, Vue } from 'vue-property-decorator'
import GHeader from '../Home/compontents/Header.vue'

@Component({
  components: {
      GHeader
  }
})
export default class Terms extends Vue {}
