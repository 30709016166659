import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import prototypeExpand from "@/basic/prototypeExpand"
import preventReClick from "@/basic/directive"
Vue.config.productionTip = false
Vue.use(preventReClick)
Vue.use(prototypeExpand)

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  document.title = "Monster Legend";
  next()
})

router.afterEach(() => {
  document.title = "Monster Legend";
})