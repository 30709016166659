<template>
  <div class="bg">
    <!-- <img class="icon-img" src="@/assets/images/pic_slogan_new.png" alt=""> -->
    <div class="item">
        <div class="cell" @click="$emit('terms')"><p>Terms of Service</p><img src="@/assets/images/go_btn.png" alt=""></div>
        <div class="cell" @click="$emit('privacy')"><p>Privacy Policy</p><img src="@/assets/images/go_btn.png" alt=""></div>
        <div class="cell" @click="$emit('contact')"><p>Rules of Conduct</p><img src="@/assets/images/go_btn.png" alt=""></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewFoot',
  components: {
  },

  data() {
    return {
      
    }
  },

  created() {
    
  },

  methods: {
  }

}
</script>

<style lang="less" scoped>

.bg {
    height: 91px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;

    .icon-img {
        width: 70px;
        height: 41px;
    }

    .item {
        color: black;
        font-size: 8px;

        .cell {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: space-between;
            align-items: center;
            height: 20px;
            width: 120px;
        }

        img {
            width: 12px;
            height: 12px;
        }
    }
}

</style>