<template>
  <div class="drawLuckSpin">
    <img class="title-img" src="@/assets/images/over_num_caught.png" alt="">
    <div class="num">01
    </div>
    <img class="titleOrnament" src="https://cdn.amusgame.net/game/6/8/2251645744625606700.png" alt="">
    <h3>Lucky Spin</h3>
    <h2>LUCK SPIN</h2>
    <div :class="['luckContent', {'active': starting}]">
      <div class="start" v-preventReClick @click="start"></div>
      <ul :class="['gifts', {'active': starting}]">
        <li
          v-for="item in list"
          :key="item.prizeId">
          <img :src="item.prizeImgUrl" alt="">>
        </li>
      </ul>
      <span class="checkGifts" v-preventReClick @click="checkGifts">Check your rewards</span>
    </div>
    <e-alert
      :show="alertShow"
      :title="alertTitle"
      @close="alertShow = false">
      <template>
        <div class="alertContent">
          <template v-if="alertContent.type">
            <div>You’ve won <br>{{ alertContent.text }}</div>
            <div class="code">
              <b>Code</b>
              <div>{{ alertContent.code }}</div>
            </div>
          </template>
          <template v-else>
            <div class="errText">{{ alertContent.text }}</div>
          </template>
        </div>
      </template>
    </e-alert>
  </div>
</template>
<script>
import EAlert from './EAlert'
export default {
  name: 'DrawLucySpin',
  components: {
    'e-alert': EAlert
  },
  data() {
    return {
      starting: false,
      uid: null,
      listNum: 0,
      alertTitle: 'Congrats',
      alertShow: false,
      alertContent: {
        type: true,
        code: null,
        text: ''
      },
      list: {

      }
    }
  },
  created() {
    let uid = localStorage.getItem('draw-uid')
    if(!uid) {
      uid = this.makeUuid()
      localStorage.setItem('draw-uid', uid)
    }
    this.uid = uid
    console.log(uid, 12390213)
    this.getList()
  },
  methods: {
    getList() {
      this.listNum ++
      this.$get({
        url: 'activity.anonym_prizes',
        params: {
          activityName: 'luckyspin'
        }
      }).then(res => {
        console.log(res)
        let data = {}
        res.data.map((item) => {
          data[item.prizeId] = item
        })
        this.list = data
      }).catch(() => {
        if (this.listNum > 4) return
        setTimeout(() => {
          this.getList()
        }, 1000)
      })
    },
    start() {
      if (this.starting) return
      this.starting = true
      this.time = +new Date() 
      this.$post({
        url: 'activity.anonym_prize_draw',
        params: {
          uid: this.uid,
          activityName: 'luckyspin'
        }
      }).then(res => {
        let time = 2000 - (+new Date() - this.time)
        if (time < 0) {
          this.drawDone(res)
        } else {
          setTimeout(() => {
            this.drawDone(res)
          }, time)
        }
        
      }).catch((e) => {
        this.starting = false
        this.alertTitle = 'Note'
        if (e.code == 998) {
          e.msg = "No more spin times"
        }
        this.alertContent = {
          type: false,
          code: null,
          text: e.msg
        }
        this.alertShow = true
      })

    },
    drawDone(res) {
      this.starting = false
      this.alertTitle = 'Congrats'
      this.alertContent = {
        type: true,
        code: res.data.prizeSecret,
        text: this.list[res.data.prizeId].prizeName
      }
      this.alertShow = true
    },
    checkGifts() {
      this.$get({
        url: 'activity.anonym_prize_record',
        params: {
          uid: this.uid,
          activityName: 'luckyspin'
        }
      }).then(res => {
        if (!res.data.length) {
          this.alertTitle = 'Note'
          this.alertContent = {
            type: false,
            code: null,
            text: 'Please spin first'
          }
          this.alertShow = true
          return 
        }
        this.alertTitle = 'Congrats'
        this.alertContent = {
          type: true,
          code: res.data[0].prizeSecret,
          text: this.list[res.data[0].prizeId].prizeName
        }
        this.alertShow = true
      }).catch((e) => {
        if (e.code == 998) {
          e.msg = "No more spin times"
        }
        this.alertTitle = 'Note'
        this.alertContent = {
          type: false,
          code: null,
          text: e.msg
        }
        this.alertShow = true
      })
    },
    makeUuid() {
      var tick = parseInt(Date.now() / 1000),
      max = 1e+9,
      rand = parseInt((1+Math.random()) * max);
      return tick.toString(16) + rand.toString(16);
    }
  }
}
</script>
<style lang="less" scoped>
.drawLuckSpin {
  position: relative;
  padding-top: 154px;
  color: #1C3986;
  text-align: center;
  .title-img {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 48px;
    object-fit: contain;
  }
  .titleOrnament {
    position: absolute;
    width: 220px;
    top: 20px;
    left: -38px;
  }
  .num {
    font-size: 48px;
    color: #4E97F9;
    font-weight: bold;
    position: absolute;
    width: 92px;
    height: 92px;
    top: 90px;
    left: 50%;
    transform: translate(-50%, 0);
    background-image: url("~@/assets/images/new/num_bg.png");
    background-size: 100% 100%;
    line-height: 90px;
  }
  h2, h3 {
    position: relative;
    z-index: 2;
  }
  h3 {
    font-weight: normal;
    font-size: 15px;
    opacity: 0;
  }
  h2 {
    font-weight: bold;
    font-size: 25px;
  }
  .luckContent {
    width: 340px;
    height: 451px;
    margin: 16px auto;
    background-image: url("https://cdn.amusgame.net/game/6/8/2251645744625606698.png");
    background-size: 100% 100%;
    position: relative;
    .gifts {
      position: absolute;
      left: 40px;
      top: 74px;
      width: 260px;
      height: 260px;
      &.active {
        animation: rotate .6s linear infinite;
      }
      li {
        width: 40px;
        position: absolute;
        img {
          width: 100%;
        }
        &:nth-child(1) {
          left: 50%;
          transform: translate(-50%, 8px);
        }
        &:nth-child(2) {
          left: 66%;
          top: 30px;
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          right: 24px;
          top: 50%;
          transform: translate( 0, -50%) rotate(90deg);
        }
        &:nth-child(4) {
          left: 65%;
          top: 154px;
          transform: rotate(135deg);
        }
        &:nth-child(5) {
          left: 50%;
          transform: translate(-50%, 0) rotate(180deg);
          bottom: 7px;
        }
        &:nth-child(6) {
          left: 19%;
          top: 152px;
          transform: rotate(225deg);
        }
        &:nth-child(7) {
          left: 24px;
          top: 50%;
          transform: translate( 0, -50%) rotate(-90deg);
        }
        &:nth-child(8) {
          left: 19%;
          top: 30px;
          transform: rotate(-45deg);
        }
      }
    }
    .start {
      position: absolute;
      width: 80px;
      height: 80px;
      background-image: url("~@/assets/images/new/btn_spin.png");
      background-size: 100% 100%;
      top: 50%;
      left: 50%;
      z-index: 10;
      transform: translate(-50%, -76%);
    }
  }
  
  .checkGifts {
    font-size: 12px;
    color: #6F9FFF;
    padding-right: 2px;
    text-decoration: underline;
    position: absolute;
    right: 32px;
    bottom: 72px;
  }
  
  .alertContent {
    font-size: 15px;
    color: #000;
    .code {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0 8px;
      div {
        width: 220px;
        height: 36px;
        background-color: #fff;
        box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.0784313725490196);
        border-radius: 28px 28px 28px 28px;
        line-height: 36px;
        color: #1C3986;
        font-weight: bold;
        text-align: left;
        text-indent: 15px;
      }
    }
    .errText {
      margin-bottom: 15px;
    }
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  99.99% {
    transform: rotate(365deg);
  } 
  100% {
    transform: rotate(0deg);
  } 
}
</style>