<template>
  <div class="drawPrePrize">
    <div class="title-num">01</div>
    <div class="phone-text">
      <div class="title1">Pre-order the Game</div>
      <div class="title2">WIN LIMITED PRIZE</div>
    </div>
    <div class="prize">
      <div class="prize1"><img v-if="archievedShow(1)" src="@/assets/images/new/icon_achieved.png" alt=""></div>
      <div class="prize2"><img v-if="archievedShow(2)" src="@/assets/images/new/icon_achieved.png" alt=""></div>
      <div class="prize3"><img v-if="archievedShow(3)" src="@/assets/images/new/icon_achieved.png" alt=""></div>
      <div class="prize4"><img v-if="archievedShow(4)" src="@/assets/images/new/icon_achieved.png" alt=""></div>
      <div class="prize5"><img v-if="archievedShow(5)" src="@/assets/images/new/icon_achieved.png" alt=""></div>
    </div>
    <div class="pic-charizard"><img src="https://cdn.amusgame.net/game/6/8/2251645744625606699.png" alt=""></div>
    <div class="num"><div class="bottom-line mr8"></div><div v-for="(num, index) in numList" :key="index" :class="styleClass(num)"></div><div class="bottom-line ml8"></div></div>
    <div class="bottom-text">TRAINERS HAVE PRE-REGISTERED</div>
  </div>
</template>

<script>
export default {
  name: 'DrawPrePrize',
  data() {
    return {
      startTime: 1647403200,
      currentTime: 0,
      peopleNum: 0,
      numList: [0],
    }
  },
  created() {
    this.$get({
        url: 'activity.anonym_sync_time',
        params: {}
      }).then(res => {
        this.currentTime = parseInt(res.data/1000);
        this.getPrePeopleNum();
        this.setupNumList();
      })
  },
  methods: {
    styleClass(num) {
      return 'numStyle'+num;
    },
    archievedShow(num) {
      var s = this.currentTime-this.startTime;
      return s>(100000*num)
    },
    setupNumList() {
      var str = this.peopleNum + "";
      var arr = [];
      str.split("").forEach(function (item) {
        arr.push(parseInt(item));
      })
      this.numList = arr;
    },
    getPrePeopleNum() {
      var s = this.currentTime-this.startTime;
      if (s>0) {
        this.peopleNum = s;
        return s;
      }
      return 0;
    }
  },
}
</script>

<style lang="less" scoped>

.drawPrePrize {
  text-align: center;
}

.title-num {
  font-size: 48px;
  color: #4E97F9;
  font-weight: bold;
  position: absolute;
  width: 92px;
  height: 92px;
  top: 15px;
  left: 50%;
  transform: translate(-50%, 0);
  background-image: url("~@/assets/images/new/num_bg.png");
  background-size: 100% 100%;
  line-height: 90px;
}

.phone-text {
  text-align: center;
  padding-top: 8.3vw;
  position: relative;
  & .title1 {
    font-size: 15px;
    font-family: 'bahnschrift'; 
    line-height: 15px;
    color: #1C3986;
    margin-top: 20px;
  }
  & .title2 {
    font-size: 23px;
    font-family: 'bahnschrift';
    font-weight: bold;
    line-height: 25px;
    color: #1C3986;
    margin-top: 4px;
  }
}

.bottom-text {
  font-size: 16px;
  font-family: 'bahnschrift'; 
  font-weight: bold;
  line-height: 16px;
  color: #1C3986;
  margin-top: 5px;
}

.prize {
  display: inline-block;
  background: url(~@/assets/images/new/prize-pikachu.png) no-repeat;
  background-size: 100% 100%;
  width: 76vw;
  height: 124vw;
  margin-top: -48px;
  & img {
    width: 83%;
    margin-top: 17%;
  }
  .prize1 {
    background: url(~@/assets/images/new/prize1.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: 27.5vw;
    left: 11.2vw;
    width: 23.6vw;
    height: 23.6vw;
  }
  .prize2 {
    background: url(~@/assets/images/new/prize2.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: 16vw;
    left: 45.1vw;
    width: 23.6vw;
    height: 23.6vw;
  }
  .prize3 {
    background: url(~@/assets/images/new/prize3.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: 17vw;
    left: 9.3vw;
    width: 23.6vw;
    height: 23.6vw;
  }
  .prize4 {
    background: url(~@/assets/images/new/prize4.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: 8.5vw;
    left: 56vw;
    width: 23.6vw;
    height: 23.6vw;
  }
  .prize5 {
    background: url(~@/assets/images/new/prize5.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: 13vw;
    left: 12.4vw;
    width: 23.6vw;
    height: 23.6vw;
  }
}

.pic-charizard {
  width: 46vw;
  height: 46vw;
  margin-left: 58.3vw;
  margin-top: -20vw;
  & img {
    width: 100%;
  }
}

.num {
  display: flex;
  height: 25px;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: center;
  align-items: flex-end;
  .bottom-line {
    width: 105px;
    height: 100%;
    border-bottom: 1px solid #1C3986;
  }
}

.mr8 {
  margin-right: 8px;
}

.ml8 {
  margin-left: 8px;
}

.numStyle0 {
  margin: 1px;
  width: 13px;
  height: 22.27px;
  background:transparent url(~@/assets/images/new/pic_num.png) no-repeat scroll -25px -18px;
}
.numStyle1 {
  margin: 1px;
  width: 8.15px;
  height: 21.82px;
  background:transparent url(~@/assets/images/new/pic_num.png) no-repeat scroll -41px -18px;
}
.numStyle2 {
  margin: 1px;
  width: 14.97px;
  height: 22.03px;
  background:transparent url(~@/assets/images/new/pic_num.png) no-repeat scroll -53px -18px;
}
.numStyle3 {
  margin: 1px;
  width: 15.22px;
  height: 22.23px;
  background:transparent url(~@/assets/images/new/pic_num.png) no-repeat scroll -69px -18px;
}
.numStyle4 {
  margin: 1px;
  width: 16.29px;
  height: 21.82px;
  background:transparent url(~@/assets/images/new/pic_num.png) no-repeat scroll -86px -18px;
}
.numStyle5 {
  margin: 1px;
  width: 14.66px;
  height: 22.03px;
  background:transparent url(~@/assets/images/new/pic_num.png) no-repeat scroll -104px -18px;
}
.numStyle6 {
  margin: 1px;
  width: 14.6px;
  height: 22.03px;
  background:transparent url(~@/assets/images/new/pic_num.png) no-repeat scroll -121px -18px;
}
.numStyle7 {
  margin: 1px;
  width: 14.33px;
  height: 21.82px;
  background:transparent url(~@/assets/images/new/pic_num.png) no-repeat scroll -137px -18px;
}
.numStyle8 {
  margin: 1px;
  width: 15.57px;
  height: 22.23px;
  background:transparent url(~@/assets/images/new/pic_num.png) no-repeat scroll -154px -18px;
}
.numStyle9 {
  margin: 1px;
  width: 15.6px;
  height: 22.03px;
  background:transparent url(~@/assets/images/new/pic_num.png) no-repeat scroll -171px -18px;
}
.numStyle10 {
  margin: 1px;
  margin-top: 5px;
  width: 5.82px;
  height: 11.99px;
  background:transparent url(~@/assets/images/new/pic_num.png) no-repeat scroll -188.03px -28.98px;
}

</style>