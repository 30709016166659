import axios from 'axios'
axios.defaults.timeout = 90000

axios.defaults.withCredentials = true

axios.interceptors.request.use(config => {
	return config
}, error => {
	Promise.reject(error)
})

axios.defaults.validateStatus = function (status) {
	return status >= 200 && status <= 500 // 返回状态码区间
}
axios.interceptors.response.use(res => {
	if (res) {
		if (res.status != 200 || res.data.code !== 0) {
			return Promise.reject(res.data)
		} else {
			return res.data
		}
	}
	return Promise.reject('error')
}, error => {
	console.log('err' + error)
})
export default axios
