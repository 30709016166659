<template>
  <div class="">
    <div class="txt">
        <p class="p1">MEDIA</p>
        <!-- <p class="p2">WHETHER IN THE SKY, SEA, FOREST, OR IN TOWNS,</p>
        <p class="p2">POKÉMON CAN BE SEEN IN EVERY CORNER OF THE WORLD</p> -->
        <img class="mline" src="@/assets/images/line.png" alt="">
    </div>
    <div class="pic">
        <!-- <img preview="0" src="https://cdn.amusgame.net/game/6/2/2512856483665879053.jpg" alt="">
        <img preview="0" src="https://cdn.amusgame.net/game/6/2/2512856483665879054.jpg" alt="">
        <img preview="0" src="https://cdn.amusgame.net/game/6/2/2512856483665879055.jpg" alt="">
        <img preview="0" src="https://cdn.amusgame.net/game/6/2/2512856483665879056.jpg" alt=""> -->

        <!-- <img preview="0" src="https://cdn.amusgame.net/game/6/2/3074590787396927502.png" alt="">
        <img preview="0" src="https://cdn.amusgame.net/game/6/2/3074590787396927503.png" alt="">
        <img preview="0" src="https://cdn.amusgame.net/game/6/2/3074590787396927504.png" alt="">
        <img preview="0" src="https://cdn.amusgame.net/game/6/2/3074590787396927505.png" alt="">
        <img preview="0" src="https://cdn.amusgame.net/game/6/2/3074590787396927506.png" alt=""> -->

        <img preview="0" src="https://cdn.amusgame.net/game/3/8/3368405617466007552.png" alt="">
        <img preview="0" src="https://cdn.amusgame.net/game/6/8/3368405617466007553.png" alt="">
        <img preview="0" src="https://cdn.amusgame.net/game/6/8/3368405617466007554.png" alt="">
        <img preview="0" src="https://cdn.amusgame.net/game/6/8/3368405617466007555.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppMedia',
  components: {
  },

  data() {
    return {
      
    }
  },

  created() {
    
  },

  methods: {
  }

}
</script>

<style lang="less" scoped>

.txt {
    color: black;
    font-family: 'din-bold';
    text-align: center;

    .p1 {
        font-size: 25px;
        margin-top: 25px;
        margin-bottom: 5px;
    }
    .p2 {
        font-size: 12px;
    }
    .mline {
        width: calc( 100vw - 18px - 72px);
        height: 4.5vw;
        margin-top: 5px;
    }
}

.pic {
    background: url(https://cdn.amusgame.net/game/6/2/2512856483665879062.jpg) center center no-repeat;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
    padding: 12px;

    img {
        width: 310px;
        height: 174px;
        border-radius: 4px;
        margin: 5px;
    }
}

</style>