import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home/Home.vue'
import Home1 from '../views/Home/Home1.vue'
import Contact from '../views/Other/Contact.vue'
import Privacy from '../views/Other/Privacy.vue'
import Terms from '../views/Other/Terms.vue'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home1,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  
]

const router = new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
