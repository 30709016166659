




























































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class Home extends Vue {
  
  @Prop({
    type: Boolean,
    default: false,
  })
  private banner?: Boolean

}
