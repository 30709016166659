





















import { Component, Vue } from 'vue-property-decorator'
import Banner from './compontents/Banner1.vue'
import GHeader from './compontents/Header.vue'
import DrawPrePrize from './compontents/DrawPrePrize.vue'
import DrawGettGift from './compontents/DrawGettGift.vue'
import DrawLuckSpin from './compontents/DrawLuckSpin.vue'
import Media from './compontents/Media.vue'
import MediaSwiper from './compontents/MediaSwiper.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import AppInfo from './compontents/new/AppInfo.vue'
import AppMedia from './compontents/new/AppMedia.vue'
import PetInfo from './compontents/new/PetInfo.vue'
import NewFoot from './compontents/new/NewFoot.vue'

@Component({
  components: {
    Banner,
    GHeader,
    Swiper,
    SwiperSlide,
    'draw-pre-prize': DrawPrePrize,
    'draw-get-gift': DrawGettGift,
    "draw-luck-spin": DrawLuckSpin,
    "media-new": Media,
    "media-swiper": MediaSwiper,
    "app-info": AppInfo,
    "app-media": AppMedia,
    "pet-info": PetInfo,
    "new-foot": NewFoot,
  }
})
export default class Home1 extends Vue {
  private showStatus: any = {
    banner: false,
    getIt: false,
    getItContent: true,
    media: false,
    foot: false,
    footPhone: false
  } 
  private headerShow: Boolean = false
  private videoShow: Boolean = false
  public $refs: any
  private swiperOptions: any = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoplay: true,
    loop : true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  }
  
  public get swiper(){
    return this.$refs.mySwiper.$swiper
  }

  mounted() {
    setTimeout(()=>{
      this.showStatus.banner = true
    })
  }

  destroyed(){
  }

  private closeVideo() {
    this.videoShow = false;
  }

  private videoClick() {
    this.videoShow = true;
  }

  private terms() {
    this.$router.push({path:'/terms'});
  }

  private contact() {
    this.$router.push({path:'/contact'});
  }

  private privacy() {
    this.$router.push({path:'/privacy'});
  }

  private registerClick() {
    this.appstoreClick();
  }

  private appstoreClick() {
    window.location.href = 'https://apps.apple.com/app/monster-legend/id6469328875';
    (window as any).fbq('track', 'AddToCart');
    (window as any).gtag('event', 'add_to_cart', {});
  }

  private isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return flag;
    }

}

