<template>
  <div class="drawGettGift">
    <div class="num">02
    </div>
    <h3>Enter your email</h3>
    <h2>WIN EXCLUSIVE  REWARDS</h2>
    <div class="giftsContent">
      <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <input type="text" v-model="email" placeholder="Enter your email">
      <span class="claim" v-preventReClick @click="claim"></span>
      <div class="checkGifts" v-preventReClick @click="checkGifts"><span>Check your rewards</span></div>
    </div>
    <e-alert
      :show="alertShow"
      :title="alertTitle"
      @close="alertShow = false">
      <template>
        <div class="alertContent">
          <template v-if="alertContent.type">
            <div>You’ve won <br>the pre-registration reward</div>
            <div class="code">
              <b>Code</b>
              <div>{{ alertContent.code }}</div>
            </div>
          </template>
          <template v-else>
            <div class="errText">{{ alertContent.text }}</div>
          </template>
        </div>
      </template>
    </e-alert>
  </div>
</template>
<script>
import { emailReg } from '@/filters/reg'
import EAlert from './EAlert'
export default {
  name: "DrawGettGift",
  components: {
    'e-alert': EAlert
  },
  data() {
    return {
      email: '',
      alertTitle: 'Congrats',
      alertShow: false,
      alertContent: {
        type: true,
        code: null,
        text: ''
      }
    }
  },
  created() {
    this.email = localStorage.getItem('draw-email')
  },
  methods: {
    claim() {
      if (!emailReg.test(this.email)) {
        this.alertTitle = 'Note'
          this.alertContent = {
            type: false,
            code: null,
            text: 'Please enter the right email'
          }
          this.alertShow = true
        return
      }
      localStorage.setItem('draw-email', this.email)
      this.$post({
        url: 'activity.anonym_prize_draw',
        params: {
          uid: this.email,
          activityName: 'winexclusive'
        }
      }).then(res => {
        this.alertTitle = 'Congrats'
        this.alertContent = {
          type: true,
          code: res.data.prizeSecret
        }
        this.alertShow = true
      }).catch((e) => {
        this.alertTitle = 'Note'
        if (e.code == 998) {
          e.msg = "No more spin times"
        }
        this.alertContent = {
          type: false,
          code: null,
          text: e.msg
        }
        this.alertShow = true
      })

    },
    checkGifts() {
      this.$get({
        url: 'activity.anonym_prize_record',
        params: {
          uid: this.email,
          activityName: 'winexclusive'
        }
      }).then(res => {
        if (!res.data.length) {
          this.alertTitle = 'Note'
          this.alertContent = {
            type: false,
            code: null,
            text: 'Please enter the right email'
          }
          this.alertShow = true
          return 
        }
        this.alertTitle = 'Congrats'
        this.alertContent = {
          type: true,
          code: res.data[0].prizeSecret
        }
        this.alertShow = true
      }).catch((e) => {
        this.alertTitle = 'Note'
        if (e.code == 998) {
          e.msg = "No more spin times"
        }
        this.alertContent = {
          type: false,
          code: null,
          text: e.msg
        }
        this.alertShow = true
      })
    }
  }
}
</script>
<style lang="less" scoped>
.drawGettGift {
  text-align: center;
  color: #1C3986;
  position: relative;
  padding-top: 100px;
  .num {
    font-size: 48px;
    color: #4E97F9;
    font-weight: bold;
    position: absolute;
    width: 92px;
    height: 92px;
    top: 38px;
    left: 50%;
    transform: translate(-50%, 0);
    background-image: url("~@/assets/images/new/num_bg.png");
    background-size: 100% 100%;
    line-height: 90px;
  }
  h2, h3 {
    position: relative;
    z-index: 2;
  }
  h3 {
    font-weight: normal;
    font-size: 15px;
  }
  h2 {
    font-weight: bold;
    font-size: 25px;
    margin: 4px 0 18px;
  }
  .giftsContent {
    width: 340px;
    height: 237px;
    background-image: url("~@/assets/images/new/bg_email.png");
    background-size: 100% 100%;
    margin: 0 auto;
    padding: 30px;
    box-sizing: border-box;
    ul {
      display: flex;
      justify-content: space-between;
    }
    li {
      width: 50px;
      height: 50px;
      background-image: url("~@/assets/images/new/bg_email prize.png");
      background-size: 100% 100%;
      &:nth-child(1) {
        &::before {
          content: "";
          display: inline-block;
          width: 40px;
          height: 40px;
          background: url("~@/assets/images/new/pic_email rewards.png") 9px 10px no-repeat;
          background-size: auto 31px;
        }
      }
      &:nth-child(2) {
        &::before {
          content: "";
          display: inline-block;
          width: 40px;
          height: 40px;
          background: url("~@/assets/images/new/pic_email rewards.png") -33px 10px no-repeat;
          background-size: auto 31px;
        }
      }
      &:nth-child(3) {
        &::before {
          content: "";
          display: inline-block;
          width: 40px;
          height: 40px;
          background: url("~@/assets/images/new/pic_email rewards.png") -76px 10px no-repeat;
          background-size: auto 31px;
        }
      }
      &:nth-child(4) {
        &::before {
          content: "";
          display: inline-block;
          width: 40px;
          height: 40px;
          background: url("~@/assets/images/new/pic_email rewards.png") -112px 10px no-repeat;
          background-size: auto 30px;
        }
      }
      &:nth-child(5) {
        &::before {
          content: "";
          display: inline-block;
          width: 40px;
          height: 40px;
          background: url("~@/assets/images/new/pic_email rewards.png") -153px 6px no-repeat;
          background-size: auto 30px;
        }
      }
    }
    input {
      width: 100%;
      height: 21px;
      background: #E1F0FE;
      border-radius: 5px 5px 5px 5px;
      opacity: 1;
      border: 1px solid #30415A;
      margin: 30px 0 23px;
      font-size: 13px;
      color: #5273B5;
      text-indent: 5px;
    }
    .claim {
      display: inline-block;
      width: 106%;
      margin-left: -3%;
      height: 46px;
      background-image: url("~@/assets/images/new/btn_clam.png");
      background-size: 100% 100%;
    }
    .checkGifts {
      text-align: right;
      padding-top: 6px;
      span {
        font-size: 12px;
        color: #1B50BC;
        padding-right: 2px;
        text-decoration: underline;
      }
    }
  }
  .alertContent {
    font-size: 15px;
    color: #000;
    .code {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0 8px;
      div {
        width: 220px;
        height: 36px;
        background-color: #fff;
        box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.0784313725490196);
        border-radius: 28px 28px 28px 28px;
        line-height: 36px;
        color: #1C3986;
        font-weight: bold;
        text-align: left;
        text-indent: 15px;
      }
    }
    .errText {
      margin-bottom: 15px;
    }
  }
}
</style>