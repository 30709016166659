














import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class Header extends Vue {
  private isPhone: Boolean = false
  private availWidth: number = window.screen.availWidth

  mounted() {
    this.setup();
  }
  private setup() {
    if (this.availWidth<1000.1) {
      this.isPhone = true;
    } else {
      this.isPhone = false;
    }
  }
}
